import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Parallax } from 'react-scroll-parallax';

import { Link } from "gatsby"
import Fade from 'react-reveal/Fade'

import Seo from '../components/seo'

import Logo from '../assets/images/logo.svg'
import Badge from '../assets/images/ico-badge.inline.svg'

class RepTemplate extends Component {

  render() {
    let page = this.props.data.wpPage

    let { leftImage, rightImage, BottomImage, representatives  } = this.props

    return (
      <>
        <Seo title={he.decode(page.seo.title)} bodyClass={page.slug} description={page.seo.metaDesc} />

        <section id="list" className="representation-list">
          
          { console.log(page) }

          <div className='representation-list__item'>
            <div className='item__0'>
              <Parallax translateY={[-15, 20]}>
                <div className='first-img'>
                  <GatsbyImage className='representation-list__image' loading='lazy' image={getImage(page.acf.leftImage?.localFile)} alt='Origi' />
                </div>
              </Parallax>
            </div>
          </div>

          <div className='representation-list__item'>
            <div className='item__1'>
              <div className='details'>
                { page.acf.representatives && page.acf.representatives.map((el, i) => (
                  <div className='representation-item'>
                      {  el.title  &&
                        <h3>{ el.title }</h3>
                      }
                      { el.details &&
                        <span dangerouslySetInnerHTML={{ __html: el.details }} />
                      }
                    </div>
                )) }
              </div>

              <div className='details-image'>
                <Parallax translateY={[-15, 20]}>
                  <div className='first-img'>
                    <GatsbyImage className='representation-list__image' loading='lazy' image={getImage(page.acf.rightImage?.localFile)} alt='Origi' />
                  </div>
                </Parallax>
              </div>
            </div>
          </div>

          <div className='representation-list__item'>
            <div className='item__2'>
              <Parallax translateY={[-15, 20]}>
                <div className='first-img'>
                  <GatsbyImage className='representation-list__image' loading='lazy' image={getImage(page.acf.bottomImage?.localFile)} alt='Origi' />
                </div>
              </Parallax>
            </div>
          </div>

        { page.acf.aboutImages && page.acf.aboutImages.map((el, i) => (
            <div key={i} className='representation-list__item'>

                {i === 1 &&
                  <div className='representation-list__ico-badge'><Badge /></div>
                }

                {i === 2 &&
                  <div className='representation-list__ico-badge two'><Badge /></div>
                }

                {i === 5 &&
                  <div className='representation-list__ico-badge three'><Badge /></div>
                }

                {i === 8 &&
                  <div className='representation-list__ico-badge four'><Badge /></div>
                }

                {i === 12 &&
                  <div className='representation-list__ico-badge four'><Badge /></div>
                }

                <div className={'item__' + i }>

                  <Parallax translateY={[-15, 20]}>
                    <div className='first-img'>
                      <GatsbyImage className='representation-list__image' loading='lazy' image={getImage(el.image?.localFile)} alt='Origi' />
                    </div>
                  </Parallax>

                  {i === 1 &&
                    <div>
                      <Parallax translateY={[-15, 20]}>
                        <div className='representation-list__description' dangerouslySetInnerHTML={{ __html: page.acf.description  }} />
                      </Parallax>
                    </div>
                  }
                </div>
            </div>
          )) }
          </section>
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        leftImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 75
                width: 1800
                formats: [AUTO, WEBP]
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
        rightImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 75
                width: 1800
                formats: [AUTO, WEBP]
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
        bottomImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 75
                width: 1800
                formats: [AUTO, WEBP]
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
        representatives {
          title
          details
        }
      }
    }
  }
`

export default RepTemplate
